.page-content {
    .banner-bg {
        height: 90vh;

        &::before {
            content: "";
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
        }
    }

}