@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .page-navbar {
        @apply fixed w-full flex flex-wrap items-center justify-between py-2 navbar-expand-lg navbar-light;
    }

    .nav-menu {
        @apply p-0;
    }

    .mobile-view-btn {
        @apply border-0 hover:shadow-none hover:no-underline py-2 px-2.5 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline;
    }

    .flex-container {
        @apply flex items-center;
    }

    .vertical-navs {
        @apply nav-link my-0 pl-3 flex items-center;
    }

    /* Modal Calsses */
    .modal {
        @apply fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto;
    }
    .modal-dialog {
        @apply modal-dialog-centered relative pointer-events-none;
    }
    .modal-content {
        @apply border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current;
    }
    .modal-header {
        @apply flex flex-shrink-0 items-center justify-between pb-0 p-4;
    }
    .modal-footer {
        @apply flex flex-shrink-0 flex-wrap items-center justify-end p-4;
    }
    .btn-close {
        @apply  box-content w-4 h-4 p-1 text-black border-none rounded-none focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline;
    }

    /* Carousal buttons */
    .carousel-control-prev {
        @apply  absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0;
    }
    .carousel-control-next {
        @apply  absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0;
    }

    .carousel-control-next-icon,.carousel-control-prev-icon {
        @apply inline-block bg-no-repeat;
    }

    .carousel-item {
        @apply relative float-left w-full;
    }

    /* Gap classes */
    .box-gap {
        @apply xl:gap-5 lg:gap-4 md:gap-2 gap-5 md:p-0 lg:p-0 p-5;
    }

    .cart-notification {
        @apply absolute h-fit justify-center text-xs text-white px-1.5 py-0.5 shadow rounded-full;
    }
}

@layer utilities {

    html,
    #root,
    #page-wrapper {
        --theme-primaryColor: #74ab50;
        --theme-darkColor: #3e4826;
        --theme-secondayColor: #0f0101;
    }

    .primary-clr {
        color: var(--theme-primaryColor);
    }

    .secondary-clr {
        color: var(--theme-secondayColor);
    }

    .dark-clr {
        color: var(--theme-darkColor);
    }

    .bg-primary-clr {
        background-color: var(--theme-primaryColor);
    }

    .bg-secondary-clr {
        background-color: var(--theme-secondayColor);
    }

    .bg-dark-clr {
        background-color: var(--theme-darkColor);
    }

    .text-white {
        color: #fff;
    }
    
    .bg-white {
        background-color: #fff;
    }
}